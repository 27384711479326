.circle_graph_container {

    position: relative;
    width: 100px;
    height: 100px;
    text-align: center;
    font-size: 40px;
    color: #4cc1bd;
    background-color: white;
    border-radius: 50%;
    border: 10px solid #4cc1bd;
    padding-top: 9px;
    
}