.phone_battery_container{
    display: flex;
    flex-direction: row;
    margin: 1px;
}

.phone_battery_container * {
    margin: 1px;
}

.phone_battery_name {
    max-width: 500px;
    align-self: center;
} 

.phone_battery_charging{ background-color: orange !important; }
.bg-colour-primary { background-color: #4cc1bd;}
.phone_battery_low {background-color: red !important;}

.battery_level_phone_img {
    width: 50px;
}

.phone_battery_level {
    background-color: #4cc1bd;
    width: 30%;
    height: 100%;
    border-radius: 20px;
    margin: 0px;
    border: 3px solid #4cc1bd;
}

.phone_battery_guage{
/*
    margin: 5px;
    border: 3px solid #4cc1bd;
    min-width: 200px;
    color: white;
    min-width: 96px;
    border-radius: 20px;


line-height: 24px;
    
    */

    padding: 0px;
    font-size: 12px;
    font-weight: bold;
    border: none;
    box-shadow: 0 0 0 2px #4cc1bd;
    min-width: 96px;
    height: 32px;
    border-radius: 20px;
    background-color: white;
}