.barchart_bar { fill: url(#svgGradient); }

.barchart_bar:hover {
    stroke: var(--primaryColour); 
    stroke-width: 3px;    
}

.barchart_bartext{fill: var(--primaryColour);}

.barchart_bartext_title{fill: var(--chartBarTitleColour);}

.barchart_bar-bp-red { fill: red; }

.charter_datatable { width: 100%; }

.charter_datatable th {
    background-color: var(--primaryColour);
    color: var(--lighthighlightColour);
    padding: 2px;
    border-radius: 1px;
}

.charter_datatable td {
    background-color: var(--lighthighlightColour);
    color: var(--chartTickColour);
}