.LiveDashboard_Container{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
}

.livecallheader {
    padding-left: 60px;
    position: relative;
    font-size: 20px;
    color: gray;
}