.live_call_container {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 320px;
    max-width: 400%;
    width: auto;
}

.live_call_button {
    margin-left: 10px;
    margin-right: 10px;   
    border-radius: 8px;
    border: none;
    color: white;
    padding: 2px;
    box-shadow: 0px -5px 15px rgba(50, 50, 50, 0.15);
    margin-bottom: 2px;
    width: 96%;
    /*
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: top;
    position: relative;
    width: auto;
    */
}

.live_call_card {
    width: 100%;
    display: grid;
    grid-template-columns: 40px auto 70px;
    grid-template-rows: auto;
    column-gap: 10px;
    row-gap: 10px;
    margin: 3px;
  }

  
  .caretooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted #4cc1bd; /* If you want dots under the hoverable text */
    margin:1px;
}

.caretooltip .caretooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #4cc1bd;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    border: 1px solid white;
    position: absolute;
    z-index: 1;
}

.text-align-left {
    text-align: left;
}

.care_selected { height:25px }

.flex-column {
    display: flex;
    flex-direction: column;
  }
  
.flex-row {
    display: flex;
    flex-direction: row;
 }

.dashboard_item_section {
    /*border: 1px solid gray;*/
    width: 100%;
    padding: 3px;
    margin-top: 10px;
  }

/* Show the tooltip text when you mouse over the tooltip container */
.caretooltip:hover .caretooltiptext {
    visibility: visible;
}


.caretooltip_comment {
    justify-content:center;
    height:100%;
}

.caretooltip_comment .caretooltiptext_comment {
    visibility: hidden;
    width: 120px;
    background-color: darkgray;
    color: #fff;
    text-align: center;
    padding: 1px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.caretooltip_comment:hover .caretooltiptext_comment {
    visibility: visible;
}

.dashboard_careclick_comment_icon {
    background-color: lightgray;
    color: darkgray;
    font-size: 15px;
    font-weight: bold;
    height: 20px;
    width: 20px;
    border-radius:4px;
    margin:1px;
}

.caretooltiptext_comment_title{
    font-weight:bold;
    font-size: 14px;
    color:white;
    text-align:left;
    padding-left: 10px;        
}

.caretooltiptext_comment_text{
    background-color:white;
    color:darkgray;
    margin: 2px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding:2px;
    text-align:left;
    padding-left: 10px;
}