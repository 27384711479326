.formInputAlert {
    font-weight: 400;
    font-style: italic;
    font-size: 10px;
    color: red;
    display: block;
}

.formInputTitle {
    color: grey;
    font-size: 2vh;
}