.reports_resident_container{
    border-radius: 10px;
    padding: 0px;
    background-color: #4cc1bd;
  }
  
  .reports_resident_container_header {
    padding: 5px;
    color: white;
  }
  
  .reports_callreport_container_main {
    padding-bottom: 10px;
    background-color: white;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }


  .reports_callreport_container{
    margin-top: 15px;
    border-radius: 10px;
    padding: 0px;
    color: "white";
    border: "1px solid";
    background-color: white;
    padding-bottom: 10px;
  }

  
  .reports_callreport_container_header{
    padding: 5px;
  }
  
  .reports_resident_container_main {
    padding-top: 10px;
    background-color: white;
  }