.dashboard_completed_calls_container {
  border-radius: 6px;
  padding-left: 10px;
  cursor: pointer;
  text-align: left;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  height: 39px;
  line-height: 45px;
}

.dashboard_completed_calls_container img { align-self: center; }

.dashboard_call_summary_layout{
    display: grid;
    grid-template-columns: auto 150px auto auto;
    
}


.dashboard_completed_calls_item { 
    font-size: 2.3vh;
    font-weight: 300;
}

.dashboard_call_summary_container{
  min-width: 320px;
  max-width: 600px;
  color: white;
  flex-grow: 3;
  margin-top: 20px;
}

.dashboard_All_Calls {
    background-color: #4cc1bd;
}
.dashboard_Call {
    background-color: #F4804D;
}
  
  .dashboard_Emergency {
    background-color: #EC1848;
  }
  
  .dashboard_Assistance {
    background-color: #F8DA3C;
  }
  
  .dashboard_Accessory {
    background-color: #914397;
  }
  
  .dashboard_Attendance {
    background-color: #93C967;
  }

  .dashboard_Care_Call {
    background-color: rgb(225, 21, 131);
  }

  .dashboard_Visit {
    background-color: rgb(1, 87, 63);
  }