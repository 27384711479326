.ResidentCard {
  background-color: var(--primaryColour);
  color: var(--lighthighlightColour);
  border-radius: 6px;
  padding: 10px;
  width: 90%;
  cursor: pointer;
  column-gap: 5px;
  display: grid;
  grid-template-columns: 70px 250px 100px 100px;
  margin: 5px;
}

.ResidentCard .avatar {
  width: 20%;
}

.ResidentCard .avatar img {
  width: 40px;
  vertical-align: middle;
  border-radius: 50%;
}

.ResidentCard .details {
  display: flex;
  flex-direction: row;
}


/* Blocks (rather than default rows) */

.ResidentCardBlocks {
  background-color: var(--primaryColour);
  color: var(--lighthighlightColour);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 7px;
  max-width: 300px;
  max-height: 200px;
  cursor: pointer;
}

.ResidentCardBlocks .avatar {
  width: 20%;
}

.ResidentCardBlocks .avatar img {
  width: 90%;
  vertical-align: middle;
  
  border-radius: 50%;
}
.ResidentCardBlocks .details {
  display: flex;
  flex-direction: column;
}