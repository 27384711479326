@keyframes splashfade{
    0%{opacity:0}
    50%{opacity:1}
    100%{opacity:0}
}

.splashFading{
    display:    block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 40%;

    animation:splashfade 5s infinite}
