div.DropDownContainer {
    width:100%;
    margin: 0 auto;
}

div.DropDownHeader{
    margin-bottom: 0.8em;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    border: 1px #4cc1bd solid;
    height: 23px;
    color: #4cc1bd;
    padding-left: 6px;
    background-color: white;
}


div.DropDownListContainer {
  z-index: 900;
  float: none;
  color: #4cc1bd;
  text-decoration: none;
  text-align: left;
  background-color:white;
  min-width: 160px;
  z-index: 900;
  position: absolute;
}

div.DropDownList {
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #4cc1bd;
  box-sizing: border-box;
  color: #4cc1bd;
  font-size: 1.0rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
}

div.ListItem{
  list-style: none;
  margin-bottom: 0.8em;
}


div.DropDownContainer[version="secondary"] {
    background-color: red !important;
} 