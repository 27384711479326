
.dash_item_border{
    border-radius: 6px;
    border: 1px solid black;
}

.tick {color: var(--chartTickColour);}

.widget_value {
    fill: var(--primaryColour);
 /*   font-size: 40px;*/
    font-weight: 700;
}

.barchart_bar {
    fill: url(#svgGradient);

}
.barchart_bar:hover {
    stroke: var(--primaryColour); 
    stroke-width: 3px;    
}
.barchart_bartext{fill: var(--primaryColour);}
.barchart_bartext_title{fill: var(--chartBarTitleColour);}
.dashboard_chart {
    text-align: center;
    display: inline-block;
    position: relative;
    width: 100%;
    /*padding-bottom: 100%;*/
    /*min-height: 200px;*/
    vertical-align: top;
    overflow: hidden;
    height: 100%;
   /* margin-top: 30px;*/
   flex-grow:1;
}

.fixed-size {
    overflow: scroll;
}

@media screen and (max-width: 1000px) {
    .dashboard_chart {
        width: 100% !important;
        height: 100% !important;
    }
} 



.svg-content {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}

.chartTitle {
    overflow:hidden;
    display:inline-block;
    color: var(--chartTitleColour);
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 5px;
    font-weight: 600;
    margin-top: 1px;
    min-height: 20px;
}

.chartSubTitle {
    color: var(--chartSubTitleColour);
    font-size: small;
    margin-left: 5px;
    margin-right: 2px;
    margin-bottom: 5px;
    overflow:hidden;
    /*display:inline-block;*/
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 20px;
}

.testitem {
    background-color: #4cc1bd;
}

.dashboard_container {
    display: grid;
    /*grid-template-columns: repeat(4, 250px);*/
    grid-template-rows: repeat(8, 200px);
    grid-template-columns: repeat(5, 220px);
    background-color: lightgray;
    /*max-width: 1520px;*/
    grid-gap: 20px;
    padding: 20px;
    overflow-x: hidden;
    transition: height .15s ease-in-out;
    transition-property: height;
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;

}

.dashboard_editing_banner{
    width: 100%;
    margin: 10px;
    padding: 10px;
}

dialog {
    background-color: white;
    padding: 50px;
    min-height: 400px;
    border: none !important;
    border-radius: calc(5px * var(--ratio));
    box-shadow: 0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    padding: 1.6rem;
    max-width: 400px;
}

dialog::backdrop {
    background-color: #dcf5f3;
    width: 100%;
    height: 100%;
}

.resize_chart {
    cursor: pointer;
    color: black;
}

.greyItem {
    background-color: lightgray !important;
}
.dashboard_item {
    
    
    border: 1px solid black;
    border-radius: 6px;
    background-color: white;
    transition: transform .15s ease-in-out;
    overflow: hidden;
    
   /* 
    justify-content: space-between;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    -webkit-column-break-inside: avoid;
    padding: 24px;
    box-sizing: border-box;
    */
}
/*
  .dashboard_item:first-child {
    height: 485px;
  }

  .dashboard_item:nth-child(2) {
    height: 200px;
  }

  .dashboard_item:nth-child(3) {
    height: 265px;
  }
*/

.drag_item {
    cursor: move;
    padding: 10px;
    margin-bottom: 1px;
    transition: all 0.2s;
}

.drag_item:hover { background-color: #dcf5f3!important; }

.col{
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    height:100%;
}

  
.col_span_1 {grid-column: span 1;}
.col_span_2 {grid-column: span 2;}
.col_span_3 {grid-column: span 3;}
.col_span_4 {grid-column: span 4;}
.col_span_5 {grid-column: span 5;}

.row_span_1 {grid-row: span 1;}
.row_span_2 {grid-row: span 2;}
.row_span_3 {grid-row: span 3;}
.row_span_4 {grid-row: span 4;}
.row_span_5 {grid-row: span 5;}


button.reverse {
    display: block;
    margin: 0 auto;
}

.dashboard_report {
    display: flex;
    flex-direction: column;
    padding: 2%;
}
.dashboard_widget .title { 
    font-weight: 600;
    color: var(--chartTitleColour); }
.dashboard_widget .subtitle { 
    font-size: small;
    color: var(--chartTitleColour); }
.dashboard_widget .direction { color: var(--primaryColour); }


.dashboard_widget {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 2%;
}
.dashboard_widget .title { 
    font-weight: 600;
    color: var(--chartTitleColour); }
.dashboard_widget .subtitle { 
    font-size: small;
    color: var(--chartTitleColour); }
.dashboard_widget .direction { color: var(--primaryColour); }
.dashboard_widget .value { 
    color: var(--primaryColour); 
    font-size: 4vh; 
    text-align: center; 
    margin-top: 10px; 
    font-weight: 600; 
}
.dashboard_widget .caption { 
    color: var(--chartTitleColour); font-size: smaller;
}

.red { color: red !important; }


.dashboard_chart .title { 
    font-weight: 600;
    color: var(--chartTitleColour); 
}
.dashboard_chart .subtitle { 
    font-size: small;
    color: var(--chartTitleColour); 
}


@media screen and (max-width: 1000px) {
    
    .dashboard_container {
        display: flex;
        flex-direction: column;
        background-color: lightgray;
        width: 100%;
        position: relative;

    /*
        padding: 10px;
        padding-right: 23px;
        */
    } 
}