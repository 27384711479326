/************************ BODY *************************/
#AIDA {
  padding-top: 18px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
}
/************************ LOGIN *************************/
.divLogin {
  background-color: white;
  padding: 8px 16px!important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  animation: 0.7s ease-out 0s 1 mainFadeIn;
  margin-top: 100px;
}


.divLogin div {
  background-color: white;
  padding: 8px 16px!important;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.loginLogo {
    width: 500px;
}

/************************ MAIN PANE (CONTAINS NAV AND CONTENT) *************************/
#AIDA_NAV_MAIN
{
  order: 1;
  display: flex;
  flex-direction: row;
  flex: 1;
}

#rpt_selected_resident {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background-color: #4cc1bd;
  color: white    ;
}

#rpt_selected_resident span {

  margin-right: 2em;
  font-size: 1.5em;

}


.aida_resident_container {
  
  background-color: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  color: #4cc1bd;
  padding-top: 15px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

#aida_care_report_table tr th {
  background-color: rgb(125, 205  , 205);
}
#aida_care_report_table tr td {
  background-color: white;
  color: #4cc1bd;
}

#aida_care_insights_preview {
  width: 60%;
  margin-left: 20%;
  margin-right: 25%;
  
}


.aida_resident_container {

  overflow-x: hidden;
  overflow-y: auto;
}

.careplan_section {
  
  background-color: white;
  color: #4cc1bd;
  margin: 1em;

}

.aida_care_insights_graph_container {
  width: 34%;
  margin-left: 10px;
/*    background-color: #4cc1bd; */
  
}

#aida_care_insights_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 4;
  justify-content: space-around;
}

#aida_cp_actions_container {

}

.aida_cp_action {

}

.aida_cp_incident {

}

#aida_cp_incidents_container {}


#aida_cp_pNotes_container {
  display: flex;
  flex-direction: column;
  flex-grow: 4;
  background-color: #dcf5f3;

}

.aida_cp_pNote {
  width: 100%;
}

.aida_cp_pNote span {
  background-color: white;
  color:#4cc1bd;
  width: 100%;
  display: block;
  border-radius: 1px;
}

#AIDA_CONTENT {
flex: 1;
/*  margin-top: 13vh; */
}


.aida_cp_section_header_container {
  display: flex;
  flex-direction: row;
}


.aida_cp_res_details_container {
  background-color: white;
  color: #4cc1bd;
  width: 100%;
  padding: 20px;
}




.aida_cp_action span{
  margin: 1px;
  border-radius: 2px;
}


.aida_cp_action .title {
}

.aida_cp_action .date, .time, .source, .information, .followup, .comments {
  background-color: gray;
}

.icon_circular {
  height: 61px;
  margin-right: 30px;
  border-radius: 50%;
  border: 12px solid #4cc1bd;
  background-color: #4cc1bd;
  padding-left: 5.5px;
  padding-right: 5.5px;
}

#aida_cp_personal_info_button {
  background-color: white;
  border-radius: 5px;
  color: #4cc1bd;
}

.title {font-weight: bold;}

.cp_entry_toggle_container {background: transparent;}
.cp_entry_toggle_container img {width: 20px;}
.cp_entry_toggle_container img:checked{background-color:red;}
.cp_entry_toggle_container label {padding: 5px;}
.cp_entry_toggle_container label:hover {padding: 5px; background-color: #4cc1bd;}
.cp_entry_toggle_container label:checked{background-color:red;}
.cp_entry_toggle_container input[type="radio"] {display: none;}
.cp_entry_toggle_container input[type="radio"]:checked+label[for$="positive"] {background:rgba(146,200,102,.7) !important;}
.cp_entry_toggle_container input[type="radio"]:checked+label[for$="neutral"] {background:rgba(144,146,149,.7) !important;}
.cp_entry_toggle_container input[type="radio"]:checked+label[for$="negative"] {background:rgba(248,173,64,.7) !important;}
.cp_entry_toggle_container input[type="radio"]+label{background:rgba(226,227,228,.7);}
.cp_entry_toggle_container label[for$="positive"]{
border-top-left-radius: 6px;
border-bottom-left-radius: 6px;
/* background:red !important; */
}
.cp_entry_toggle_container label[for$="negative"]{
border-top-right-radius: 6px;
border-bottom-right-radius: 6px;
}

/*hidden fields by ID*/
.cp_entry_class_toggle [id*="cp_entry_id_"]
.cp_entry_class_toggle [id*="cp_entry_assignedto_"],
.cp_entry_class_toggle [id*="cp_entry_completedby_"],
.cp_entry_class_toggle [id*="cp_entry_entrydate_"],
.cp_entry_class_toggle [id*="cp_entry_id_"]
{display: none;}


.cp_entry_class_toggle [id*="cp_entry_assignedto_"] {display: none;}

.entry_comments_container span {
  color: #4cc1bd;
  display: block;
}

.expand_collapse {
display: none;
}

span.expand_collapse {
font-size: 0px;
}

.collapsible {    
  border-bottom: 3px solid grey;
  cursor: pointer;
  padding: 18px;
  display: flex;
  flex-flow: row;
  vertical-align: middle;
}
/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .collapsible:hover {
  background-color: #dcf5f3;
}

.add_new_button{cursor: pointer;}

#btn_login{
  border-radius: 4px;
}

.entry_layout:hover{background-color: #dcf5f3;}

/* Style the collapsible content. Note: hidden by default */
.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: 10px;
/*    background-color: #f1f1f1; */
}

.collapsible:after {
  content: '\02795'; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: white;
  float: right;
  margin-left: 5px;
  vertical-align: middle;
}

.active:after {
  content: "\2796"; /* Unicode character for "minus" sign (-) */
}

.toggle_image {
  height: 20px;
  weight: 20px;
  padding: 5px;
  background-color: lightgray;
}

.toggle_image:hover{
  height: 25px;
  weight: 25px;
  background-color: #4cc1bd;  
}

.sectionImage {display: inline;}

#personal_care {
background-color: #dcf5f3;
display: flex;
flex-direction: column;
vertical-align: middle;
padding: 25px;
}

#personal_care hr {
margin-top: 10px;
margin-bottom: 10px;
}

#personal_care span {
margin-bottom: 5px;  
}

#personal_care input {
border: 0;
margin-top: 2px;
margin-bottom: 2px;
padding-left: 5px;
padding-right: 5px;
padding-top: 5px;
padding-bottom: 5px;
color: #4cc1bd;
}

.section_header 
{
    display: flex;
    flex-flow: row;
}

.entry_layout 
{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 3px;
    align-items: center;
}

.measurement_layout
{
  display: flex;
  flex-flow: row;
}

/*
RTN.appendChild(getImageElement("", "toggle_image", "images/icons/tick.png"));
RTN.appendChild(getImageElement("", "toggle_image", "images/icons/questionmark.png"));
RTN.appendChild(getImageElement("", "toggle_image", "images/icons/cross.png"));
*/


.toggle_positive{
border-top-left-radius: 25px;
border-bottom-left-radius: 25px;
}
.toggle_negative {
height: 10px;
}
.toggle_neutral {width:10px}

/************************************************************************/
/************************************************************************/
/************************************************************************/
/************************************************************************/
/************************************************************************/
/************************************************************************/
/************************************************************************/
/***************************   3 way toggle  ****************************/
/************************************************************************/
/************************************************************************/
/************************************************************************/
/************************************************************************/
/************************************************************************/
/************************************************************************/
/************************************************************************/
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);


.wrapper {
margin: 100px 0;
}
.toggle_radio{
position: relative;
background: rgba(255,255,255,.1);
margin: 4px auto;
overflow: hidden;
padding: 0 !important;
-webkit-border-radius: 50px;
-moz-border-radius: 50px;
border-radius: 50px;
position: relative;
height: 26px;
width: 318px;
}
.toggle_radio > * {
float: left;
}
.toggle_radio input[type=radio]{
display: none;
}
.toggle_radio label{
font: 90%/1.618 "Source Sans Pro";
color: rgba(255,255,255,.9);
z-index: 0;
display: block;
width: 100px;
height: 20px;
margin: 3px 3px;
-webkit-border-radius: 50px;
-moz-border-radius: 50px;
border-radius: 50px;
cursor: pointer;
z-index: 1;
text-align: center;
}
.toggle_option_slider{
width: 100px;
height: 20px;
position: absolute;
top: 3px;
-webkit-border-radius: 50px;
-moz-border-radius: 50px;
border-radius: 50px;
-webkit-transition: all .4s ease;
-moz-transition: all .4s ease;
-o-transition: all .4s ease;
-ms-transition: all .4s ease;
transition: all .4s ease;
}
#first_toggle:checked ~ .toggle_option_slider{
background: rgba(255,255,255,.3);
left: 3px;
}
#second_toggle:checked ~ .toggle_option_slider{
background: rgba(255,255,255,.3);
left: 109px;
}
#third_toggle:checked ~ .toggle_option_slider{
background: rgba(255,255,255,.3);
left: 215px;
}



.imgDiv 
{
  padding-right: 20px;
}

.titleDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}




@keyframes slideDownFromTop {
0% {
    transform: translatey(-100%);
    opacity: 0;
}
100% {
    transform: translatey(0);
    opacity: 1;
}

}

@keyframes mainFadeIn {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}

}

html, body {

font-family: 'Muli', sans-serif !important;
font-size: 15px;
line-height: 1.5;
margin-top: 0;
margin-left: 0;
margin-right: 0;
margin-bottom: 0;
padding: 0;
background-color: white;
background-size: cover;
color:#4cc1bd;
}



.main {
position: relative;
flex: 1 1 auto;
/*left:   15em;*/
}

.residents{
background-color: #dcf5f3;   
margin: 0em;

}

.residents li {
list-style: none;
margin: 0.5em;
}

.residents li:hover {
/*
background-color: #4cc1bd;
color: white;
*/
}

.resident
{
background-color: 4cc1bd;
color: white;
margin: 4em;
}

.section {
background-color: white;
}

/************HIDDEN ITEMS**********************/
.entry_ID, .entry_type, .entry_answer, .resident_ID {
display: none;
}

.entry_title, .entry_options, .entry_care_period {
display: inline;
margin-right: 10em;
}

.careplan_entry {
background-color: dcf5f3;
color: 4cc1bd;
}

.careplan_entry:hover {
background-color: 4cc1bd;
color: dcf5f3;
}

.resident_careplan {
margin: 7em;
}




.sectionHeader {
background-color: #4cc1bd;
color: dcf5f3;
font-weight: bold;
}

.header_logo {
  width: 159px;
  height: 48px;

}

.h4 { 
  font-size: calc(0.9rem + 0.3vw) !important;
  font-weight: bold !important; 
}

#aida_care_resident_card_container .resident_card 
{
background-color: #4cc1bd;
color: white;
margin: 5px;
display: flex;
justify-content: flex-start;
flex-wrap: nowrap;
flex-flow: row;
border-radius: 10px;
padding: 10px;
align-content: center;
min-width: 280px;
max-height: 121px;
max-width: 200px
}

#aida_care_resident_card_container .resident_card_details hr {
color: white;
width: 100%;

}

#aida_care_resident_card_container .resident_card_details {
display: flex;
flex-direction: column;
flex-wrap: nowrap;
width: 54%;
}

#aida_care_resident_card_container .resident_card img {
height: 5em;
margin-right: 30px;
border-radius: 50%;
border: 5px solid white;
}


.resident_detail_content {
  margin: 20px;
  color: #4cc1bd;
  padding-left: 20px;
  padding-right: 20px;
}



#rpt_selected_resident .resident_circle {
height: 5em;
margin-right: 30px;
border-radius: 50%;
border: 5px solid white;
}

.imgRed {
border-color: red !important;
}



#cp_nav_residents_list .resident_card 
{
color: #4cc1bd;
width: 70%;
margin: 5px;
display: flex;
justify-content: flex-start;
flex-wrap: nowrap;
flex-flow: row;
border-radius: 20px;
padding: 10px;
align-content: center;
}

#cp_nav_residents_list .resident_card:hover 
{
background-color: #4cc1bd;
color: white;
}

#cp_nav_residents_list .resident_card .resident_card_details hr {
color: white;
width: 100%;
}

#cp_nav_residents_list .resident_card .resident_card_details {
display: flex;
flex-direction: column;
flex-wrap: nowrap;
}


#cp_nav_residents_list .resident_card img {
height: 2em;
margin-right: 30px;
border-radius: 50%;
border: 3px solid white;
}


#cp_nav_residents_list .resident_card .resident_circle {
height: 2em;
margin-right: 30px;
border-radius: 50%;
border: 5px solid white;
}


#cp_nav_residents_list .resident_card .resident_total,
#cp_nav_residents_list .resident_card .resident_total,
#cp_nav_residents_list .resident_card hr 
{
display: none;
}

/***  ***/

/*
#cp_nav_residents_list .resident_card::after {
content: '<hr>';
}
*/



.action_options span {
font-size: small;
}

.action_checkbox {
display: flex;
flex-direction: column;
}

.action_entry{
display: flex;
flex-direction: column;
border: 1px #4cc1bd solid;
margin-left: 5px;
margin-right: 5px;
margin-top: 20px;
margin-bottom: 20px;
padding: 10px;
}

.action_top{
display: flex;
flex-direction: row;
justify-content: space-between;
}

.action_options{
display: flex;
flex-direction: row;
}

.action_bottom{
display: flex;
flex-direction: column;
}

.action_bottom span {
font-weight: bold;
}

.action_bottom textarea {
border: 1px #4cc1bd solid;
}


.meals {
border: 1px #4cc1bd solid;
display:flex;
flex-direction: column;
margin-top: 15px;
padding: 15px;
}

.meals div {
padding-left: 15px;
}


.meal_section {
display: flex;
flex-direction: column;
background-color: #dcf5f3;
margin-bottom: 15px;
}

.meal_container {
display: flex;
flex-direction: row;
justify-content: space-between;
margin-left: 10px;
vertical-align: center;
}



.incident_table {
border: 1px #4cc1bd solid;
}

.incident_table {
border: 1px #4cc1bd solid;
}

.popup_form {
display: flex;
flex-direction: column;
padding: 25px;
}

.incident_layout {
display: flex;
flex-direction: row;
}

.measurement_record {
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
}

.measurement_record div {
margin-left: 10px;
margin-right: 10px;
margin-top: 5px;
margin-bottom: 5px;
}

.measurement_record div[id*="cp_measure_msr_id_"],
.measurement_record div[id*="cp_measure_msr_completed_by_"],
.measurement_record div[id*="cp_measure_msr_completed_by_id_"]
{display: none;}

.total_bar{
background-color: #4cc1bd;
color: white;
font-weight: bold;
display: flex;
flex-direction: row;
justify-content: space-between;
}


.subsections
{
margin-left: 50px;
margin-right: 50px;
}

.subsections div {
margin-left: 2px;
}




.AIDA_NAV {
order: -1;
flex: 0 1 auto;
background: #dcf5f3;
display: flex;
flex-direction: column;
color: #4cc1bd;
min-height: 100vh;
padding: 20px;
margin:top;
min-width: 220px;
}

nav {
/*
background: #dcf5f3;
padding: 20px;
margin:top;
height: 100%; 
border-radius: 1px;
color: #4cc1bd;
font-family: muli;
overflow: visible;
flex: 0 0 10em;
animation: 0.7s ease-out 0s 1 slideFromLeft;
display: flex;
flex-direction: column;
*/    
}


nav ul {
list-style: none;
}


nav ul li:hover {
background-color: #4cc1bd;
color: white;
}


.main {
position: relative;
flex: 1 1 auto;
/*left:   15em;*/
}

.residents{
background-color: #dcf5f3;   
margin: 0em;

}

.residents li {
list-style: none;
margin: 0.5em;
}



.resident
{
background-color: 4cc1bd;
color: white;
margin: 4em;
}

.section {
background-color: white;
}

/************HIDDEN ITEMS**********************/
.entry_ID, .entry_type, .entry_answer, .resident_ID {
display: none;
}

.entry_title, .entry_options, .entry_care_period {
display: inline;
margin-right: 10em;
}

.careplan_entry {
background-color: dcf5f3;
color: 4cc1bd;
}

.careplan_entry:hover {
background-color: 4cc1bd;
color: dcf5f3;
}

.resident_careplan {
margin: 7em;
}

.sectionHeader {
background-color: #4cc1bd;
color: dcf5f3;
font-weight: bold;
}



#userzone{
color: white;
display: flex;
flex-direction: row-reverse;
justify-content: right;
}



/* used as a container for navigation left pane and main frame */
.nav_main 
{
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: left;
position: relative;
align-items: top;
height: 100%;
/*transition: color 200ms ease-in 50ms;*/



}





@keyframes slideFromLeft {
0% {
    transform: translatex(-100%);
    opacity: 0;
}
100% {
    transform: translatex(0);
    opacity: 1;
}    
}


.main {
position: relative;
flex: 1 1 auto;
/*left:   15em;*/
}

.residents{
background-color: #dcf5f3;   
margin: 0em;

}

.residents li {
list-style: none;
margin: 0.5em;
}



.resident
{
background-color: 4cc1bd;
color: white;
margin: 4em;
}

.section {
background-color: white;
}

/************HIDDEN ITEMS**********************/
.entry_ID, .entry_type, .entry_answer, .resident_ID {
display: none;
}

.entry_title, .entry_options, .entry_care_period {
display: inline;
margin-right: 10em;
}

.careplan_entry {
background-color: dcf5f3;
color: 4cc1bd;
}

.careplan_entry:hover {
background-color: 4cc1bd;
color: dcf5f3;
}

.resident_careplan {
margin: 7em;
}

.sectionHeader {
background-color: #4cc1bd;
color: dcf5f3;
font-weight: bold;
}



#userzone{
color: white;
display: flex;
flex-direction: row-reverse;
justify-content: right;
}



/* used as a container for navigation left pane and main frame */




.Measurements {
border: 1px #4cc1bd solid;
margin-top: 15px;
padding: 15px;
}


  
.comment_container span {
display: none;
}

.comment_container {
display: none;
flex-direction: row;
justify-content: stretch;
}

.entry_top_layer {
  display:flex;
  flex-direction: column;
  padding: 4px;
 } 

  .downarrow:focus {
  outline: 2px solid #48F8;
  }
  

  
#AIDA_HEADER
{
  background-color:#4cc1bd; 
  display: flex;
  flex-direction: row;
 /* animation: 0.7s ease-out 0s 1 slideDownFromTop;  */
  color:  #dcf5f3;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  flex: 0 0 53px;
  height:     10vh;    
  width: 100%;
  /*position: fixed;*/
  top: 0px;
}


#userzone{
  color: white;
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
}


.objMenubar {
  margin-right: 10em;
}

.objMenubar span {

  color: white;
  margin-right: 2em;
}

.objMenubar span:hover {

  font-weight: bold;
}

#header_submnu {visibility: hidden;}
#header_submnu:hover {visibility: visible;}
#header_submnu:hover {visibility: visible;}

#header_submnu_container:hover div{
  background-color: green;
  visibility: visible;
}


@keyframes menufader {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 0.8;
  }
  
}


/*Main menu options */


input.form_input {
text-align: right;
color:#4cc1bd;
}


/*
.form_input:not(:placeholder-shown) {
background-color: green;
}
*/
.form_input:focus {
background-color: #dcf5f3;
font-size: large;
}

button {
border-radius: 4px;
background-color: #4cc1bd;
border: none;
text-align: center;
/*font-size: 28px;*/
padding: 5px;
/*width: 200px;*/
transition: all 0.5s;
cursor: pointer;
/*margin: 5px;*/
color:white;
}

button:hover {
background-color: #4cc1bd;
color: black;
}

.sleep_comment {
display: flex;
flex-direction: row;
justify-content: stretch;
vertical-align: middle;

}

.sleep_comment:hover{
background-color:  #dcf5f3;
color: #4cc1bd;
}

.delete_button {
padding-left: 5px;
padding-right: 5px;
margin-left: 20px;
background-color:#4cc1bd;
color: #dcf5f3;

}

.form_input_container span {display:none;}

input.dbpending {
background-color: red;
}


/********************************
*********************************
********************************/



#myInput {
box-sizing: border-box;
/* background-image: url('searchicon.png'); */
background-position: 14px 12px;
background-repeat: no-repeat;
font-size: 16px;
padding: 14px 20px 12px 45px;
border: none;
border-bottom: 1px solid #ddd;
}

#myInput:focus {outline: 3px solid #ddd;}





.meal_container div button {min-width: 72px;}

.meal_container:hover{
background-color: #4cc1bd;
color: #dcf5f3;
}





.new_meal_button {margin: 30px;}

.loginButtons {
display: flex;
flex-direction: row;
}

.loginButtons button {
margin: 5px;
}

.CONTENT_MAIN {

}

/************* Table style ***********************/




.menu_item div {
display: none;
}

.menu_item div:hover {
display: block;
}

.dashMenu {
display: flex;
flex-direction: row;
align-items: flex-start;

}

.topmenuitem {
display: flex;
flex-direction: column;
}

.topmenuitem:hover > :last-child{
background-color: green;
display: flex;
flex-direction: column;
}

.topmenusubmenu:hover{
display: flex;
flex-direction: column;
}

.topmenusubmenu{
display: none;
flex-direction: column;
}

.dashmenumain {
background-color: #4cc1bd !important;
}

.rc-menu-item {
background-color: #4cc1bd !important;
}
.rc-menu-submenu-active {
background-color: #4cc1bd !important;
}

.DashboardItem {
padding: 8px !important;
}


.DashboarItemButton {
border-radius: 16px;
width: 300px;
height: 85px;
border: none;
box-sizing: border-box;
display: flex;
flex-direction: row;
align-items: center;
color: white;




}

.allCalls {
  background-color: #4cc1bd;
}

.emg {
background-color: #EC1848;
}

.call {
background-color: #F4804D;
}

.accessory {
background-color: #914397;
}

.attendance {
background-color: #93C967;
}

.carecall .CareCall { 
  background-color: rgb(225, 21, 131); 
}

.btn-primary {
  display:flex !important; 
  flex-direction: row !important;
  
}



.assistance {
background-color: #F8DA3C;
}
/*
.cardWallet div {
margin: 10px;
}
*/

.cardWallet {

/*
display: flex;
flex-direction: row;
flex-wrap: wrap;
padding: 10px;
*/

display: grid; 
              grid-template-columns: auto auto auto; 
              grid-row-gap: 20%; 
              grid-column-gap: 2%; 
              padding: 30px;


/*
margin: 0px auto;
max-width: 1000px;
display: grid;
gap: 40px;
grid-template-columns: repeat(3, 1fr);
grid-row-gap: 20px;


grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));

*/
/* grid-template-columns: repeat(3, 1fr); */


}

.menu-bold {
font-weight: bold;
}


.DashboardItemRight {
display: flex;
flex-direction: column;
}


.dashMenu .nav {
display: flex;
flex-direction: row;
}


.left_card {
display: flex;
flex-direction: column;
text-align: start;
}



.dashboard_top {
display: flex;
flex-direction: row;
justify-content: space-between;
width: 167px;
}


.navigationpane_list {
list-style-type: none; /* Remove bullets */
padding: 0; /* Remove padding */
margin: 0; /* Remove margins */
}

.aida_logo_main {
width: 11%;
align-items: center;
display: block;
margin-left: auto;
margin-right: auto;
margin-bottom: 29px;
}





.nav-item {
  padding-right: 30px;
  align-self: center;
}




.css-1fs3xdi:hover {
background-color: #dcf5f3;
}

.css-1fs3xdi > div {
color: #4cc1bd !important;

}



.signinLogo {
margin-top: 30px;
width: 40%;
align-items: center;
display: block;
margin-left: auto;
margin-right: auto;
}

.loginObjects {
display: flex;
flex-direction: column;
align-items: center;

}

.resident_report_main {
display: flex;
flex-direction: column;
flex-wrap: nowrap;
width: 100%;
margin-top: 20px;
padding-left: 18em;
padding-right: 18em
}

.reportcard{
border-radius: 3px;
border: 2px solid #4cc1bd;
margin-top: 10px;
margin-left: 30px; 
}


.topbarwidgets {

display: flex;
flex-direction: row;

}


.site_headers {
display: flex;
flex-direction: row;
justify-content: space-around;
}


.teamContainer {
display: flex;
flex-direction: column;
}

.teamuserheader {
display: flex;
flex-direction: row;
justify-content: flex-end;
align-self: center;
}

.AIDAMAINTCONTENT {
padding: 20px;
display: flex;
/*min-height: 100vh;*/
flex-direction: column;
overflow-x: hidden;
width: 100%;
}

/*
.modal{
align-items: center;
background-color: white;
text-align: center;
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
z-index: 1000;
overflow-y: auto;
overflow-x: hidden;
border-radius: 20px;
max-width: 75%;
}

.overlay{
background-color: rgba(0,0,0,0.6);
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 999;
}

.Modal {
position: fixed;
z-index: 500;
background-color: white;
width: 70% !important;
border: 1px solid #ccc;
box-shadow: 1px 1px 1px black;
left: 15% !important;
top: 10%;
box-sizing: border-box;
transition: all 0.7s ease-out;
border-radius: 5px;
height: 70%;
overflow-y: scroll;
}

@media (min-width: 600px) {
.Modal {
    width: 500px;
    left: calc(50% - 250px);
}
}
*/


.modal_buttons {
padding: 25px;
display: flex;
flex-direction: row;
justify-content: center;
}

.modal_buttons button {
margin-left: 10px;
margin-right: 10px;
}

.modal_details {
display: flex;
flex-direction: column;
}

hr.modal {
color: #4cc1bd;
}







.navbar {
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-end;
}

Links inside the navbar 
.navbar a {
float: left;
font-size: 16px;
color: white;
text-align: center;
padding: 14px 16px;
text-decoration: none;
}




.reports_container_four {
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
display: 'flex';
flex-wrap: wrap;
}

.userpassword_container {
display: flex;
flex-direction: column;
}

.resident_detail_header {
background-color: #4cc1bd;
display: flex;
flex-direction: row;
justify-content: space-between;
color: white;
height: 60px;
vertical-align: center;
align-items: center;
width: 100%;
padding-left: 15px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
}


.resident_detail_main {
background-color: #dcf5f3;
color: #4cc1bd;
}

.alert_call_container 
{
display: flex;
flex-direction: column;
width: 50px;
}

.alert_call {
margin: 10px;
display: flex;
flex-direction: column;
min-width: 250px; 
border: 1px solid red;
}

.call_support_container {
display: flex;
flex-direction: column;
flex-wrap: nowrap;
min-width: 150px;
justify-content: space-around;
/*add border and rads*/
}

.call_support_header {
background-color: #4cc1bd;
}

.call_support_main {
background-color: white;
}



.report_resident_summary_container {
display: flex;
flex-direction: column;
/*
margin: 10px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
*/
border-radius: 10px;
border: 1px solid #4cc1bd;
padding: 0px;
background-color: #4cc1bd;
}

.report_resident_summary_header {

color: white;
padding: 5px;
color: white;
}


.summary_widget {
padding-top: 10px;
padding-bottom: 10px;
display: flex;
flex-direction: column;
flex-wrap: nowrap;
justify-content:space-between;
align-items: center;
width: 25%
}

.summary_widget div {
text-align: center;
}



.Emergency {
background-color: red;
color: white;
border: 1px solid red;
}

.Call {
background-color: rgb(245, 129, 78);
color: white;
border: 1px solid rgb(245, 129, 78);
}

.Attendance {
background-color: rgb(148, 202, 102);
color: white;
border: 1px solid rgb(148, 202, 102);
}

.reports_resident_charts_container{
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: space-between;
}


.rowFlexNoWrap {
display: flex;
flex-direction: row;
justify-content: flex-start;
vertical-align: center;
}

.summary_widget_value {
font-size: 16pt;
color: #4cc1bd;

}

.summary_widget_title {
color: #808080;
}

.support_container{
margin-left: 12%;
margin-right: 12%;
border-radius: 10px;
border: 1px solid #4cc1bd;
padding: 0px;
background-color: #4cc1bd;
margin: 50px;
min-width: 500px;
}




.support_container_header {
padding: 5px;
color: white;
}

.support_container_main {
padding: 10px;
background-color: white;
display: flex;
flex-direction: column;
flex-wrap: wrap;
border-bottom-left-radius: 9px;
border-bottom-right-radius: 9px;
}


.DashboardItemRight hr {
color: white;
}

.spaceapart {
justify-content: space-between;
}

.signout 
{
  height: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 17px
}



.centerscreenit {
align-items: center;
margin-left: 25%;
margin-right: 25%
}



.buttons_spacing {
display: flex;
flex-direction: row;
justify-content: center;

}

.buttons_spacing button {
margin: 20px;
}





.react-calendar {
width: 350px;
max-width: 100%;
background: #4cc1bd white;
border: 1px solid #a0a096;
font-family: Arial, Helvetica, sans-serif;
line-height: 1.125em;
}
.react-calendar--doubleView {
width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
display: flex;
margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
width: 50%;
margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
-moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
box-sizing: border-box;
}
.react-calendar button {
margin: 0;
border: 0;
outline: none;
}
.react-calendar button:enabled:hover {
cursor: pointer;
}
.react-calendar__navigation {
height: 44px;
margin-bottom: 1em;
}
.react-calendar__navigation button {
min-width: 44px;
background: none;
color: #4cc1bd;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
text-align: center;
text-transform: uppercase;
font-weight: bold;
font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
font-weight: bold;

}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
display: flex;
align-items: center;
justify-content: center;
font-size: 0.75em;
padding: calc(0.75em / 0.75) calc(0.5em / 0.75);

}
.react-calendar__month-view__days__day--weekend {
color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
padding: 2em 0.5em;
}
.react-calendar__tile {
max-width: 100%;
text-align: center;
padding: 0.75em 0.5em;
background: none;
}
.react-calendar__tile:disabled {
background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
background-color: #e6e6e6;
}
.react-calendar__tile--now {
background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
background: #ffffa9;
}
.react-calendar__tile--hasActive {
background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
background: #a9d4ff;
}
.react-calendar__tile--active {
background: #006edc;
color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
background-color: #e6e6e6;
}


.react-date-picker {
display: inline-flex;
position: relative;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
-moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
box-sizing: border-box;
}
.react-date-picker--disabled {
background-color: #f0f0f0;
color: #6d6d6d;
}
.react-date-picker__wrapper {
display: flex;
flex-grow: 1;
flex-shrink: 0;
border: thin solid gray;
}
.react-date-picker__inputGroup {
min-width: calc((4px * 3) +  0.54em * 8  +  0.217em * 2);
flex-grow: 1;
padding: 0 2px;
box-sizing: content-box;
}
.react-date-picker__inputGroup__divider {
padding: 1px 0;
white-space: pre;
}
.react-date-picker__inputGroup__input {
min-width: 0.54em;
height: 100%;
position: relative;
padding: 0 1px;
border: 0;
background: none;
font: inherit;
box-sizing: content-box;
-moz-appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
margin-left: -0.54em;
padding-left: calc(1px +  0.54em);
}
.react-date-picker__button {
border: 0;
background: transparent;
padding: 4px 6px;
}
.react-date-picker__button:enabled {
cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
stroke: #6d6d6d;
}
.react-date-picker__button svg {
display: inherit;
}
.react-date-picker__calendar {
width: 350px;
max-width: 100vw;
position: absolute;
top: 100%;
left: 0;
z-index: 1;
}
.react-date-picker__calendar--closed {
display: none;
}
.react-date-picker__calendar .react-calendar {
border-width: thin;
}








.react-datepicker {
font-size: 1em;
}
.react-datepicker__header {
padding-top: 0.8em;
}
.react-datepicker__month {
margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
width: 1.9em;
line-height: 1.9em;
margin: 0.166em;
}
.react-datepicker__current-month {
font-size: 1em;
}
.react-datepicker__navigation {
top: 1em;
line-height: 1.7em;
border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
border-right-color: #ccc;
left: 1em;
}
.react-datepicker__navigation--next {
border-left-color: #ccc;
right: 1em;
}





.res_header_details{
display: flex;
flex-direction: row;
justify-content: flex-start;
padding: 5px;
margin-left: 20px;
}

.res_header_detail_right{
display: flex;
flex-direction: column;
justify-content: center;
}

.res_details {
margin-left: 20px;
}

.HEADER_MENU {
height: 100%;
display: flex;
flex-direction: column;
justify-content: flex-end;
}

.HEADER_MENU div {
margin-right: 30px;
top: -15px;
}


.reports_team_container{
margin-left: 12%;
margin-right: 12%;
/*
border-top-left-radius: 10px;
border-top-right-radius: 10px;
min-width: 904px;
*/
border-radius: 10px;
/*  border: 1px solid #4cc1bd; */
padding: 0px;
background-color: #4cc1bd;
width: 100%;
}

.reports_team_container_header {
/*
background-color: #4cc1bd;
border-top-left-radius: 20px;
border-top-right-radius: 20px;
*/
width: 100%;
padding: 5px;
color: white;
}

.data_table_holder{
padding: 25px;
}





p.chartTitle {
color: #4cc1bd;
font-weight: bold;
}


.toplevelcare {
padding:50px;
}

.navbuttons {

display:flex;
flex-direction: column;
align-items: center;

}

button.submit
{
border-radius: 5px;
color:white;
width: 40%;
}

button.save,
button.print 
{
width: 40px;
border-radius: 5px;

}
img.save,
img.print 
{
height: 25px;
/*width: 20px;*/

}

/* Spinner loading */
.loader {
border: 16px solid #dcf5f3; /* Light grey */
border-top: 16px solid #4cc1bd; /* Blue */
border-radius: 50%;
width: 120px;
height: 120px;
animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}


/************************ BODY *************************/
#AIDA {
padding-top: 18px;
padding-left: 50px;
display: flex;
flex-direction: column;
}
/************************ LOGIN *************************/
.divLogin {
background-color: white;
padding: 8px 16px!important;
display: flex;
flex-direction: column;
justify-content: center;
flex-wrap: wrap;
align-content: center;
animation: 0.7s ease-out 0s 1 mainFadeIn;
margin-top: 100px;
}


.divLogin div {
background-color: white;
padding: 8px 16px!important;
text-align: center;
display: flex;
flex-direction: row;
justify-content: center;
flex-wrap: wrap;
align-content: center;
}

.loginLogo {
  width: 500px;
}

/************************ MAIN PANE (CONTAINS NAV AND CONTENT) *************************/
#AIDA_NAV_MAIN
{
order: 1;
display: flex;
flex-direction: row;
flex: 1;

/*
flex-direction: row;
flex-wrap: nowrap;
justify-content: left;
position: relative;
align-items: top;
height: 100%;
*/
/*transition: color 200ms ease-in 50ms;*/

}

/************************  *************************/
/************************  *************************/
/************************  *************************/
/************************  *************************/
/************************  *************************/
/************************  *************************/
/************************  *************************/
/************************  *************************/



#rpt_selected_resident {
display: flex;
flex-direction: row;
flex-wrap: nowrap;
align-items: center;
background-color: #4cc1bd;
color: white    ;
}

#rpt_selected_resident span {

margin-right: 2em;
font-size: 1.5em;

}


.aida_resident_container {

background-color: white;
border-top-left-radius: 25px;
border-top-right-radius: 25px;
color: #4cc1bd;
padding-top: 15px;
padding-left: 0px;
padding-right: 0px;
padding-bottom: 0px;
display: flex;
flex-direction: column;
padding-left: 5px;
padding-right: 5px;
padding-bottom: 5px;
}

#aida_care_report_table tr th {
background-color: rgb(125, 205  , 205);
}
#aida_care_report_table tr td {
background-color: white;
color: #4cc1bd;
}

#aida_care_insights_preview {
width: 60%;
margin-left: 20%;
margin-right: 25%;

}


.aida_resident_container {

overflow-x: hidden;
overflow-y: auto;
}

.careplan_section {

background-color: white;
color: #4cc1bd;
margin: 1em;

}

.aida_care_insights_graph_container {
width: 34%;
margin-left: 10px;
/*    background-color: #4cc1bd; */

}

#aida_care_insights_container {
display: flex;
flex-direction: row;
flex-wrap: wrap;
flex-grow: 4;
justify-content: space-around;
}

#aida_cp_actions_container {

}

.aida_cp_action {

}

.aida_cp_incident {

}

#aida_cp_incidents_container {}


#aida_cp_pNotes_container {
display: flex;
flex-direction: column;
flex-grow: 4;
background-color: #dcf5f3;

}

.aida_cp_pNote {
width: 100%;
}

.aida_cp_pNote span {
background-color: white;
color:#4cc1bd;
width: 100%;
display: block;
border-radius: 1px;
}

#AIDA_CONTENT {
flex: 1;
/*  margin-top: 13vh; */
}


.aida_cp_section_header_container {
display: flex;
flex-direction: row;
}


.aida_cp_res_details_container {
background-color: white;
color: #4cc1bd;
width: 100%;
padding: 20px;
}




.aida_cp_action span{
margin: 1px;
border-radius: 2px;
}


.aida_cp_action .title {
}

.aida_cp_action .date, .time, .source, .information, .followup, .comments {
background-color: gray;
}

.icon_circular {
height: 61px;
margin-right: 30px;
border-radius: 50%;
border: 12px solid #4cc1bd;
background-color: #4cc1bd;
padding-left: 5.5px;
padding-right: 5.5px;
}

#aida_cp_personal_info_button {
background-color: white;
border-radius: 5px;
color: #4cc1bd;
}




.title {font-weight: bold;}

.cp_entry_toggle_container {background: transparent;}
.cp_entry_toggle_container img {width: 20px;}
.cp_entry_toggle_container img:checked{background-color:red;}
.cp_entry_toggle_container label {padding: 5px;}
.cp_entry_toggle_container label:hover {padding: 5px; background-color: #4cc1bd;}
.cp_entry_toggle_container label:checked{background-color:red;}
.cp_entry_toggle_container input[type="radio"] {display: none;}
.cp_entry_toggle_container input[type="radio"]:checked+label[for$="positive"] {background:rgba(146,200,102,.7) !important;}
.cp_entry_toggle_container input[type="radio"]:checked+label[for$="neutral"] {background:rgba(144,146,149,.7) !important;}
.cp_entry_toggle_container input[type="radio"]:checked+label[for$="negative"] {background:rgba(248,173,64,.7) !important;}
.cp_entry_toggle_container input[type="radio"]+label{background:rgba(226,227,228,.7);}
.cp_entry_toggle_container label[for$="positive"]{
border-top-left-radius: 6px;
border-bottom-left-radius: 6px;
/* background:red !important; */
}
.cp_entry_toggle_container label[for$="negative"]{
border-top-right-radius: 6px;
border-bottom-right-radius: 6px;
}

/*hidden fields by ID*/
.cp_entry_class_toggle [id*="cp_entry_id_"]
.cp_entry_class_toggle [id*="cp_entry_assignedto_"],
.cp_entry_class_toggle [id*="cp_entry_completedby_"],
.cp_entry_class_toggle [id*="cp_entry_entrydate_"],
.cp_entry_class_toggle [id*="cp_entry_id_"]
{display: none;}


.cp_entry_class_toggle [id*="cp_entry_assignedto_"] {display: none;}

.entry_comments_container span {
color: #4cc1bd;
display: block;
}

.expand_collapse {
display: none;
}

span.expand_collapse {
font-size: 0px;
}

.collapsible {    
border-bottom: 3px solid grey;
cursor: pointer;
padding: 18px;
display: flex;
flex-flow: row;
vertical-align: middle;
}
/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .collapsible:hover {
background-color: #dcf5f3;
}

.add_new_button{cursor: pointer;}

.entry_layout:hover{background-color: #dcf5f3;}

/* Style the collapsible content. Note: hidden by default */
.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: 10px;
  /*    background-color: #f1f1f1; */
}

.collapsible:after {
  content: '\02795'; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: white;
  float: right;
  margin-left: 5px;
  vertical-align: middle;
}

.active:after {
  content: "\2796"; /* Unicode character for "minus" sign (-) */
}

.toggle_image {
  height: 20px;
  weight: 20px;
  padding: 5px;
  background-color: lightgray;
}

.toggle_image:hover{
  height: 25px;
  weight: 25px;
  background-color: #4cc1bd;  
}

.sectionImage {display: inline;}

#personal_care {
  background-color: #dcf5f3;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  padding: 25px;
}

#personal_care hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

#personal_care span {
margin-bottom: 5px;  
}

#personal_care input {
  border: 0;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #4cc1bd;
}

.section_header 
{
  display: flex;
  flex-flow: row;
}

.entry_layout 
{
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 3px;
  align-items: center;
}

.measurement_layout
{
  display: flex;
  flex-flow: row;
}


.toggle_positive {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.toggle_negative { height: 10px; }
.toggle_neutral { width:10px }

/************************************************************************/
/************************************************************************/
/************************************************************************/
/***************************   3 way toggle  ****************************/
/************************************************************************/
/************************************************************************/
/************************************************************************/
/************************************************************************/
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);


.wrapper {
margin: 100px 0;
}
.toggle_radio{
position: relative;
background: rgba(255,255,255,.1);
margin: 4px auto;
overflow: hidden;
padding: 0 !important;
-webkit-border-radius: 50px;
-moz-border-radius: 50px;
border-radius: 50px;
position: relative;
height: 26px;
width: 318px;
}
.toggle_radio > * {
float: left;
}
.toggle_radio input[type=radio]{
display: none;
}
.toggle_radio label{
font: 90%/1.618 "Source Sans Pro";
color: rgba(255,255,255,.9);
z-index: 0;
display: block;
width: 100px;
height: 20px;
margin: 3px 3px;
-webkit-border-radius: 50px;
-moz-border-radius: 50px;
border-radius: 50px;
cursor: pointer;
z-index: 1;
text-align: center;
}
.toggle_option_slider{
width: 100px;
height: 20px;
position: absolute;
top: 3px;
-webkit-border-radius: 50px;
-moz-border-radius: 50px;
border-radius: 50px;
-webkit-transition: all .4s ease;
-moz-transition: all .4s ease;
-o-transition: all .4s ease;
-ms-transition: all .4s ease;
transition: all .4s ease;
}
#first_toggle:checked ~ .toggle_option_slider{
background: rgba(255,255,255,.3);
left: 3px;
}
#second_toggle:checked ~ .toggle_option_slider{
background: rgba(255,255,255,.3);
left: 109px;
}
#third_toggle:checked ~ .toggle_option_slider{
background: rgba(255,255,255,.3);
left: 215px;
}



.imgDiv 
{
padding-right: 20px;
}

.titleDiv {
display: flex;
flex-direction: column;
justify-content: space-around;
}




@keyframes slideDownFromTop {
0% {
  transform: translatey(-100%);
  opacity: 0;
}
100% {
  transform: translatey(0);
  opacity: 1;
}

}

@keyframes mainFadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}

}

html, body {

font-family: 'Muli', sans-serif !important;
font-size: 15px;
line-height: 1.5;
margin-top: 0;
margin-left: 0;
margin-right: 0;
margin-bottom: 0;
padding: 0;
background-color: white;
background-size: cover;
color:#4cc1bd;
}



.main {
position: relative;
flex: 1 1 auto;
/*left:   15em;*/
}

.residents{
background-color: #dcf5f3;   
margin: 0em;

}

.residents li {
list-style: none;
margin: 0.5em;
}

.residents li:hover {
/*
background-color: #4cc1bd;
color: white;
*/
}

.resident
{
background-color: 4cc1bd;
color: white;
margin: 4em;
}

.section {
background-color: white;
}

/************HIDDEN ITEMS**********************/
.entry_ID, .entry_type, .entry_answer, .resident_ID {
display: none;
}

.entry_title, .entry_options, .entry_care_period {
display: inline;
margin-right: 10em;
}

.careplan_entry {
background-color: dcf5f3;
color: 4cc1bd;
}

.careplan_entry:hover {
background-color: 4cc1bd;
color: dcf5f3;
}

.resident_careplan {
margin: 7em;
}




.sectionHeader {
background-color: #4cc1bd;
color: dcf5f3;
font-weight: bold;
}




#aida_care_resident_card_container {

display: flex;
flex-wrap: wrap;
flex-direction: row;
padding:50px;
height: fit-content;
}

#aida_care_resident_card_container .resident_card 
{
background-color: #4cc1bd;
color: white;
margin: 5px;
display: flex;
justify-content: flex-start;
flex-wrap: nowrap;
flex-flow: row;
border-radius: 10px;
padding: 10px;
align-content: center;
min-width: 280px;
max-height: 121px;
}

#aida_care_resident_card_container .resident_card_details hr {
color: white;
width: 100%;

}

#aida_care_resident_card_container .resident_card_details {
display: flex;
flex-direction: column;
flex-wrap: nowrap;
}

#aida_care_resident_card_container .resident_card img {
height: 5em;
margin-right: 30px;
border-radius: 50%;
border: 5px solid white;
}






#rpt_selected_resident .resident_circle {
height: 5em;
margin-right: 30px;
border-radius: 50%;
border: 5px solid white;
}

.imgRed {
border-color: red !important;
}



#cp_nav_residents_list .resident_card 
{
color: #4cc1bd;
width: 70%;
margin: 5px;
display: flex;
justify-content: flex-start;
flex-wrap: nowrap;
flex-flow: row;
border-radius: 20px;
padding: 10px;
align-content: center;
}

#cp_nav_residents_list .resident_card:hover 
{
background-color: #4cc1bd;
color: white;
}

#cp_nav_residents_list .resident_card .resident_card_details hr {
color: white;
width: 100%;
}

#cp_nav_residents_list .resident_card .resident_card_details {
display: flex;
flex-direction: column;
flex-wrap: nowrap;
}


#cp_nav_residents_list .resident_card img {
height: 2em;
margin-right: 30px;
border-radius: 50%;
border: 3px solid white;
}


#cp_nav_residents_list .resident_card .resident_circle {
height: 2em;
margin-right: 30px;
border-radius: 50%;
border: 5px solid white;
}


#cp_nav_residents_list .resident_card .resident_total,
#cp_nav_residents_list .resident_card .resident_total,
#cp_nav_residents_list .resident_card hr 
{
display: none;
}





.action_options span {
font-size: small;
}

.action_checkbox {
display: flex;
flex-direction: column;
}

.action_entry{
display: flex;
flex-direction: column;
border: 1px #4cc1bd solid;
margin-left: 5px;
margin-right: 5px;
margin-top: 20px;
margin-bottom: 20px;
padding: 10px;
}

.action_top{
display: flex;
flex-direction: row;
justify-content: space-between;
}

.action_options{
display: flex;
flex-direction: row;
}

.action_bottom{
display: flex;
flex-direction: column;
}

.action_bottom span {
font-weight: bold;
}

.action_bottom textarea {
border: 1px #4cc1bd solid;
}


.meals {
border: 1px #4cc1bd solid;
display:flex;
flex-direction: column;
margin-top: 15px;
padding: 15px;
}

.meals div {
padding-left: 15px;
}


.meal_section {
display: flex;
flex-direction: column;
background-color: #dcf5f3;
margin-bottom: 15px;
}

.meal_container {
display: flex;
flex-direction: row;
justify-content: space-between;
margin-left: 10px;
vertical-align: center;
}




.incident_table {
border: 1px #4cc1bd solid;
}

.incident_table {
border: 1px #4cc1bd solid;
}

.popup_form {
display: flex;
flex-direction: column;
padding: 25px;
}

.incident_layout {
display: flex;
flex-direction: row;
}

.measurement_record {
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
}

.measurement_record div {
margin-left: 10px;
margin-right: 10px;
margin-top: 5px;
margin-bottom: 5px;
}

.measurement_record div[id*="cp_measure_msr_id_"],
.measurement_record div[id*="cp_measure_msr_completed_by_"],
.measurement_record div[id*="cp_measure_msr_completed_by_id_"]
{display: none;}

.total_bar{
background-color: #4cc1bd;
color: white;
font-weight: bold;
display: flex;
flex-direction: row;
justify-content: space-between;
}
/*
.modal{
align-items: center;
background-color: white;
text-align: center;
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
z-index: 1000;
overflow-y: auto;
overflow-x: hidden;
border-radius: 20px;
max-width: 75%;
}

.overlay{
background-color: rgba(0,0,0,0.6);
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 999;
}
*/
.subsections
{
margin-left: 50px;
margin-right: 50px;
}

.subsections div {
margin-left: 2px;
}



nav {
/*
background: #dcf5f3;
padding: 20px;
margin:top;
height: 100%; 
border-radius: 1px;
color: #4cc1bd;
font-family: muli;
overflow: visible;
flex: 0 0 10em;
animation: 0.7s ease-out 0s 1 slideFromLeft;
display: flex;
flex-direction: column;
*/    
}


nav ul {
list-style: none;
}


nav ul li:hover {
background-color: #4cc1bd;
color: white;
}


.main {
position: relative;
flex: 1 1 auto;
/*left:   15em;*/
}

.residents{
background-color: #dcf5f3;   
margin: 0em;

}

.residents li {
list-style: none;
margin: 0.5em;
}



.resident
{
background-color: 4cc1bd;
color: white;
margin: 4em;
}

.section {
background-color: white;
}

/************HIDDEN ITEMS**********************/
.entry_ID, .entry_type, .entry_answer, .resident_ID {
display: none;
}

.entry_title, .entry_options, .entry_care_period {
display: inline;
margin-right: 10em;
}

.careplan_entry {
background-color: dcf5f3;
color: 4cc1bd;
}

.careplan_entry:hover {
background-color: 4cc1bd;
color: dcf5f3;
}

.resident_careplan {
margin: 7em;
}

.sectionHeader {
background-color: #4cc1bd;
color: dcf5f3;
font-weight: bold;
}



#userzone{
color: white;
display: flex;
flex-direction: row-reverse;
justify-content: right;
}



/* used as a container for navigation left pane and main frame */
.nav_main 
{
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: left;
position: relative;
align-items: top;
height: 100%;
/*transition: color 200ms ease-in 50ms;*/



}





@keyframes slideFromLeft {
0% {
  transform: translatex(-100%);
  opacity: 0;
}
100% {
  transform: translatex(0);
  opacity: 1;
}    
}


.main {
position: relative;
flex: 1 1 auto;
/*left:   15em;*/
}

.residents{
background-color: #dcf5f3;   
margin: 0em;

}

.residents li {
list-style: none;
margin: 0.5em;
}



.resident
{
background-color: 4cc1bd;
color: white;
margin: 4em;
}

.section {
background-color: white;
}

/************HIDDEN ITEMS**********************/
.entry_ID, .entry_type, .entry_answer, .resident_ID {
display: none;
}

.entry_title, .entry_options, .entry_care_period {
display: inline;
margin-right: 10em;
}

.careplan_entry {
background-color: dcf5f3;
color: 4cc1bd;
}

.careplan_entry:hover {
background-color: 4cc1bd;
color: dcf5f3;
}

.resident_careplan {
margin: 7em;
}

.sectionHeader {
background-color: #4cc1bd;
color: dcf5f3;
font-weight: bold;
}



#userzone{
color: white;
display: flex;
flex-direction: row-reverse;
justify-content: right;
}



/* used as a container for navigation left pane and main frame */




.Measurements {
border: 1px #4cc1bd solid;
margin-top: 15px;
padding: 15px;
}



.comment_container span {
display: none;
}

.comment_container {
display: none;
flex-direction: row;
justify-content: stretch;
}

.entry_top_layer {
display:flex;
flex-direction: column;
padding: 4px;
} 

.downarrow:focus {
outline: 2px solid #48F8;
}



#AIDA_HEADER
{
background-color:#4cc1bd; 
display: flex;
flex-direction: row;
/* animation: 0.7s ease-out 0s 1 slideDownFromTop;  */
color:  #dcf5f3;
justify-content: space-between;
align-items: center;
font-weight: bold;
flex: 0 0 53px;
height:     10vh;    
width: 100%;
/*position: fixed;*/
top: 0px;
}


#userzone{
color: white;
display: flex;
flex-direction: row-reverse;
justify-content: right;
}


.objMenubar {
margin-right: 10em;
}

.objMenubar span {

color: white;
margin-right: 2em;
}

.objMenubar span:hover {

font-weight: bold;
}

#header_submnu {visibility: hidden;}
#header_submnu:hover {visibility: visible;}
#header_submnu:hover {visibility: visible;}

#header_submnu_container:hover div{
background-color: green;
visibility: visible;
}


@keyframes menufader {
0% {
    opacity: 0;
}
100% {
    opacity: 0.8;
}

}


/*Main menu options */


input.form_input {
text-align: right;
color:#4cc1bd;
}



/*
.form_input:not(:placeholder-shown) {
background-color: green;
}
*/
.form_input:focus {
background-color: #dcf5f3;
font-size: large;
}

button {
border-radius: 4px;
background-color: #4cc1bd;
border: none;
text-align: center;
/*font-size: 28px;*/
padding: 5px;
/*width: 200px;*/
transition: all 0.5s;
cursor: pointer;
/*margin: 5px;*/
color: white;
}

button:hover {
background-color: #4cc1bd;
color: #dcf5f3;
}

.sleep_comment {
display: flex;
flex-direction: row;
justify-content: stretch;
vertical-align: middle;

}

.sleep_comment:hover{
background-color:  #dcf5f3;
color: #4cc1bd;
}

.delete_button {
padding-left: 5px;
padding-right: 5px;
margin-left: 20px;
background-color:#4cc1bd;
color: #dcf5f3;

}

.form_input_container span {display:none;}

input.dbpending {
background-color: red;
}


/********************************
*********************************
********************************/


#myInput {
box-sizing: border-box;
/* background-image: url('searchicon.png'); */
background-position: 14px 12px;
background-repeat: no-repeat;
font-size: 16px;
padding: 14px 20px 12px 45px;
border: none;
border-bottom: 1px solid #ddd;
}

#myInput:focus {outline: 3px solid #ddd;}


.meal_container div button {min-width: 72px;}

.meal_container:hover {
background-color: #4cc1bd;
color: #dcf5f3;
}

.new_meal_button {margin: 30px;}

.loginButtons {
display: flex;
flex-direction: row;
}

.loginButtons button {
margin: 5px;
}

.CONTENT_MAIN {

}

/************* Table style ***********************/




.menu_item div {
display: none;
}

.menu_item div:hover {
display: block;
}

.dashMenu {
display: flex;
flex-direction: row;
align-items: flex-start;

}

.topmenuitem {
display: flex;
flex-direction: column;
}

.topmenuitem:hover > :last-child{
background-color: green;
display: flex;
flex-direction: column;
}

.topmenusubmenu:hover{
display: flex;
flex-direction: column;
}

.topmenusubmenu{
display: none;
flex-direction: column;
}

.dashmenumain {
background-color: #4cc1bd !important;
}

.rc-menu-item {
background-color: #4cc1bd !important;
}
.rc-menu-submenu-active {
background-color: #4cc1bd !important;
}

.DashboardItem {
padding: 8px !important;

}


.DashboarItemButton {
border-radius: 16px;
width: 300px;
height: 85px;
border: none;
box-sizing: border-box;
display: flex;
flex-direction: row;
align-items: center;
color: white;




}


.allCalls {
background-color: #4cc1bd;
}

.emg {
background-color: #EC1848;
}

.call {
background-color: #F4804D;
}

.accessory {
background-color: #914397;
}

.attendance {
background-color: #93C967;
}

.assistance {
background-color: #F8DA3C;
}
/*
.cardWallet div {
margin: 10px;
}
*/

.cardWallet {

/*
display: flex;
flex-direction: row;
flex-wrap: wrap;
padding: 10px;
*/

display: grid; 
            grid-template-columns: auto auto auto; 
            grid-row-gap: 20%; 
            grid-column-gap: 2%; 
            padding: 30px;

/*
margin: 0px auto;
max-width: 1000px;
display: grid;
gap: 40px;
grid-template-columns: repeat(3, 1fr);
grid-row-gap: 20px;
grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));

*/
/* grid-template-columns: repeat(3, 1fr); */


}

.DashboardItemRight {
display: flex;
flex-direction: column;
}

.dashMenu .nav {
display: flex;
flex-direction: row;
}


.left_card {
display: flex;
flex-direction: column;
text-align: start;
}

.dashboard_icons {
margin-left: 20px;
margin-right: 20px;
}

.dashboard_top {
display: flex;
flex-direction: row;
justify-content: space-between;
width: 167px;
}

.dashboard_bottom {
display: flex;
flex-direction: row;
justify-content: space-between;
}

.navigationpane_list {
list-style-type: none; /* Remove bullets */
padding: 0; /* Remove padding */
margin: 0; /* Remove margins */
}

.aida_logo_main {
width: 11%;
align-items: center;
display: block;
margin-left: auto;
margin-right: auto;
margin-bottom: 29px;
}










.css-1fs3xdi:hover {
background-color: #dcf5f3;
}

.css-1fs3xdi > div {
color: #4cc1bd !important;

}



.signinLogo {
margin-top: 30px;
width: 40%;
align-items: center;
display: block;
margin-left: auto;
margin-right: auto;
}

.loginObjects {
display: flex;
flex-direction: column;
align-items: center;

}

.resident_report_main {
display: flex;
flex-direction: column;
flex-wrap: nowrap;
/*  
margin-left: 70px;
margin-right: 70px;
*/
margin-top: 20px;
}

.reportcard{
border-radius: 3px;
border: 2px solid #4cc1bd;
margin-top: 10px;
margin-left: 30px; 
}


.topbarwidgets {

display: flex;
flex-direction: row;

}


.site_headers {
display: flex;
flex-direction: row;
justify-content: space-around;
}


.teamContainer {
display: flex;
flex-direction: column;
}

.teamuserheader {
display: flex;
flex-direction: row;
justify-content: flex-end;
}

.AIDAMAINTCONTENT {
padding: 20px;
display: flex;
/*min-height: 100vh;*/
flex-direction: column;
overflow-x: hidden;
width: 100%;
}

/*
.Modal {
position: fixed;
z-index: 500;
background-color: white;
width: 70% !important;
border: 1px solid #ccc;
box-shadow: 1px 1px 1px black;
left: 15% !important;
top: 10%;
box-sizing: border-box;
transition: all 0.7s ease-out;
border-radius: 5px;
overflow-y: scroll;
height: 70%;
}

@media (min-width: 600px) {
.Modal {
  width: 500px;
  left: calc(50% - 250px);
}
}
*/

.modal_buttons {
padding: 25px;
display: flex;
flex-direction: row;
justify-content: center;
}

.modal_buttons button {
margin-left: 10px;
margin-right: 10px;
}


hr.modal {
color: #4cc1bd;
}




.navbar {
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-end;
}

.navbar a {
float: left;
font-size: 16px;
color: white;
text-align: center;
padding: 14px 16px;
text-decoration: none;
}


.reports_container_four {
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
display: 'flex';
flex-wrap: wrap;
}

.userpassword_container {
display: flex;
flex-direction: column;
}




.resident_detail_main {
background-color: #dcf5f3;
color: #4cc1bd;
}

.alert_call_container 
{
display: flex;
flex-direction: column;
width: 50px;
}

.alert_call {
margin: 10px;
display: flex;
flex-direction: column;
min-width: 250px; 
border: 1px solid red;
}

.call_support_container {
display: flex;
flex-direction: column;
flex-wrap: nowrap;
min-width: 150px;
justify-content: space-around;
/*add border and rads*/
}

.call_support_header {
background-color: #4cc1bd;
}

.call_support_main {
background-color: white;
}

.call_support_contact_container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}


.report_resident_summary_container {
display: flex;
flex-direction: column;
/*
margin: 10px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
*/
border-radius: 10px;
border: 1px solid #4cc1bd;
padding: 0px;
background-color: #4cc1bd;
}

.report_resident_summary_header {

color: white;
padding: 5px;
color: white;
}


.summary_widget {
display: flex;
flex-direction: column;
flex-wrap: nowrap;
justify-content: center;
align-items: center;
}
.summary_widget div {
text-align: center;
}



.Emergency {
background-color: red;
color: white;
border: 1px solid red;
}

.Call {
background-color: rgb(245, 129, 78);
color: white;
border: 1px solid rgb(245, 129, 78);
}

.Attendance {
background-color: rgb(148, 202, 102);
color: white;
border: 1px solid rgb(148, 202, 102);
}

.visit {
  background-color: rgb(1, 87, 62);
  color: white;
  border: 1px solid rgb(1, 87, 62);
}
  

.reports_resident_charts_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}


.rowFlexNoWrap {
display: flex;
flex-direction: row;
justify-content: flex-start;
vertical-align: center;
}

.summary_widget_value {
font-size: 18pt;
color: grey;

}

.summary_widget_title {

}




.support_container{
margin-left: 12%;
margin-right: 12%;
border-radius: 10px;
border: 1px solid #4cc1bd;
padding: 0px;
background-color: #4cc1bd;
margin: 50px;
min-width: 500px;
}


.support_contacts_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
  
.support_container_header {
padding: 5px;
color: white;
}

.support_container_main {
padding: 10px;
background-color: white;
display: flex;
flex-direction: column;
flex-wrap: wrap;
border-bottom-left-radius: 9px;
border-bottom-right-radius: 9px;
}


.DashboardItemRight hr {
color: white;
}

.spaceapart {
justify-content: space-between;
}


.centerscreenit {
align-items: center;
margin-left: 25%;
margin-right: 25%
}

hr.dashboard_item {
background-color: white;
width: 100%;
border: 0px;
padding: 0px;
height: 2px !important;
color: white;
}



.buttons_spacing {
display: flex;
flex-direction: row;
justify-content: center;

}

.buttons_spacing button {
margin: 20px;
}


.forminput {
border: 0;
margin-top: 2px;
margin-bottom: 2px;
padding-left: 0px;
padding-right: 5px;
padding-top: 5px;
padding-bottom: 5px;
color: #4cc1bd;
}

.react-calendar {
width: 350px;
max-width: 100%;
background: #4cc1bd white;
border: 1px solid #a0a096;
font-family: Arial, Helvetica, sans-serif;
line-height: 1.125em;
}
.react-calendar--doubleView {
width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
display: flex;
margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
width: 50%;
margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
-moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
box-sizing: border-box;
}
.react-calendar button {
margin: 0;
border: 0;
outline: none;
}
.react-calendar button:enabled:hover {
cursor: pointer;
}
.react-calendar__navigation {
height: 44px;
margin-bottom: 1em;
}
.react-calendar__navigation button {
min-width: 44px;
background: none;
color: #4cc1bd;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
text-align: center;
text-transform: uppercase;
font-weight: bold;
font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
font-weight: bold;

}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
display: flex;
align-items: center;
justify-content: center;
font-size: 0.75em;
padding: calc(0.75em / 0.75) calc(0.5em / 0.75);

}
.react-calendar__month-view__days__day--weekend {
color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
padding: 2em 0.5em;
}
.react-calendar__tile {
max-width: 100%;
text-align: center;
padding: 0.75em 0.5em;
background: none;
}
.react-calendar__tile:disabled {
background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
background-color: #e6e6e6;
}
.react-calendar__tile--now {
background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
background: #ffffa9;
}
.react-calendar__tile--hasActive {
background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
background: #a9d4ff;
}
.react-calendar__tile--active {
background: #006edc;
color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
background-color: #e6e6e6;
}


.react-date-picker {
display: inline-flex;
position: relative;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
-moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
box-sizing: border-box;
}
.react-date-picker--disabled {
background-color: #f0f0f0;
color: #6d6d6d;
}
.react-date-picker__wrapper {
display: flex;
flex-grow: 1;
flex-shrink: 0;
border: thin solid gray;
}
.react-date-picker__inputGroup {
min-width: calc((4px * 3) +  0.54em * 8  +  0.217em * 2);
flex-grow: 1;
padding: 0 2px;
box-sizing: content-box;
}
.react-date-picker__inputGroup__divider {
padding: 1px 0;
white-space: pre;
}
.react-date-picker__inputGroup__input {
min-width: 0.54em;
height: 100%;
position: relative;
padding: 0 1px;
border: 0;
background: none;
font: inherit;
box-sizing: content-box;
-moz-appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
margin-left: -0.54em;
padding-left: calc(1px +  0.54em);
}
.react-date-picker__button {
border: 0;
background: transparent;
padding: 4px 6px;
}
.react-date-picker__button:enabled {
cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
stroke: #6d6d6d;
}
.react-date-picker__button svg {
display: inherit;
}
.react-date-picker__calendar {
width: 350px;
max-width: 100vw;
position: absolute;
top: 100%;
left: 0;
z-index: 1;
}
.react-date-picker__calendar--closed {
display: none;
}
.react-date-picker__calendar .react-calendar {
border-width: thin;
}








.react-datepicker {
font-size: 1em;
}
.react-datepicker__header {
padding-top: 0.8em;
}
.react-datepicker__month {
margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
width: 1.9em;
line-height: 1.9em;
margin: 0.166em;
}
.react-datepicker__current-month {
font-size: 1em;
}
.react-datepicker__navigation {
top: 1em;
line-height: 1.7em;
border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
border-right-color: #ccc;
left: 1em;
}
.react-datepicker__navigation--next {
border-left-color: #ccc;
right: 1em;
}


.inputTimeDisplay {
  background-color: white;
  border: 1px solid #4cc1bd;
  font-size: 14px !important;
  padding: 6px !important;
  margin: 5px;
  padding-left: 8px;
  text-align: left;
  color: #4CC1BC;
}


.inputTimeDisplay:hover{
  cursor: pointer;
}


.res_header_details{
display: flex;
flex-direction: row;
justify-content: flex-start;
padding: 5px;
margin-left: 20px;
}

.res_header_detail_right{
display: flex;
flex-direction: column;
justify-content: center;
}

.res_details {
margin-left: 20px;
}

.HEADER_MENU {
height: 100%;
display: flex;
flex-direction: column;
justify-content: flex-end;
}

.reports_team_container{
margin-left: 12%;
margin-right: 12%;
border-radius: 10px;
padding: 0px;
background-color: #4cc1bd;
width: 100%;
}

.reports_team_container_header {
width: 100%;
padding: 5px;
color: white;
}

.data_table_holder{
padding: 25px;
}



p.chartTitle {
color: #4cc1bd;
font-weight: bold;
}

.toplevelcare {
padding:50px;
}

.navbuttons {
display:flex;
flex-direction: column;
align-items: center;
}

button.submit
{
border-radius: 5px;
color:white;
width: 40%;
}

button.save,
button.print 
{
width: 40px;
border-radius: 5px;
}

img.save,
img.print 
{
height: 25px;
}

/* Spinner loading */
.loader {
border: 16px solid #dcf5f3; /* Light grey */
border-top: 16px solid #4cc1bd; /* Blue */
border-radius: 50%;
width: 120px;
height: 120px;
animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}


.purple-care {
border-radius: 5px;
color: white;
background-color: rgb(225, 21, 131);
}

.card-purple-care {
border-radius: 5px !important;
color: white;
background-color: rgb(225, 21, 131) !important;
width: 100%;
padding:15px;
}

/*
#chartDoughnut {
  width: 210px;
  height: 210px;
  margin: 0 auto;
  background: transparent url(https://i.stack.imgur.com/S7tJH.png) no-repeat center 70px;
}
*/



.css-1r4vtzz, 
.css-48ayfv {
    /*width:  100px !important;*/
    width: 100% !important;
    height: 23px !important;
}

.css-1xlg7bc-option {
    /*font-family: muli !important;*/
}
.css-1v99tuv {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 0;
    max-width: 100;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color:  #4cc1bd;
    
}
.css-1jllj6i-control {
    font-size: 12px !important;
}

.css-1pcexqc-container {
    border: 1px solid #4cc1bd;
}

.css-1gpjby2 {
    color: #4cc1bd;
}

.table-Emergency {
  background-color: red !important;
  /*opacity: 80% !important;*/
  color: white !important;
}

.table-CareCall {
  background-color: rgb(225, 21, 131) !important;
  color: white !important;
}

.table-Call {
  background-color: rgb(245, 129, 78) !important;
  color: white !important;
}
  
.table-Attendance {
  background-color: rgb(148, 202, 102) !important;
  /*opacity: 80% !important;*/
  color: white !important;
}

.table-Accessory {
  background-color: #914397 !important;
  /*opacity: 80% !important;*/
  color: white !important;
}

.table-Visit {
  background-color: rgb(1, 87, 62) !important;
  color: white !important;
  /*opacity: 80% !important;*/
}

.table-Assistance {
  background-color: #F8DA3C !important;
  color: white !important;
  /*opacity: 80% !important;*/
}

a.menu {
  color: white;
}
.width-full {   width: 100%;  }
.width-80   {   width: 80%;   }
.width-auto {   width: auto;  }
.width-30 {   width: 30%;  }
.width-40 {   width: 40%;  }

.modal-lg {
  max-width: 70% !important;
}

.openDialogue {
  background-color: #dcf5f3;
  min-width: 60%;
  height: 60%;
  border-radius: 4px;
  border: 1px solid #4cc1bd;
  
}

.onclick_link {
  color: blue;
  cursor: pointer;
}

.rounded { border-radius: 4px; }

.border-line {border: 1px solid #4cc1bd;}

.bg-main { background-color: #4CC1BC;}
.height-fit-content {height: fit-content;}
.card-min-width {min-width: 250px;}

.disabled { opacity: 0.3;}

.multidashLiveCall{
  border-radius: 4px;
}

.events_list, .events_list td, .events_list th  {
  border: black;
  border-style: solid;
  width:100%;
}

.dashboard_container {
  display: flex; 
  flex-direction: 
  column; 
  background-color: rgb(220, 245, 243); 
  border-radius: 15px; 
  color: rgb(76, 193, 189); 
  align-items: center; 
  width: 45%; 
  overflow: hidden auto; 
}

.align-center {
  text-align: -webkit-center;
}
.align-left {
  text-align: -webkit-left;
}

.dashboard_completed_calls_container {
  display: grid;
  grid-template-columns: 70px 100px auto auto;
  padding: 1px;
  width: 100%;
  text-align: center;
}
/*
.dashboard_completed_calls_item {
  font-size: 18px;
border: 1px solid rgba(0, 0, 0, 0.8);
}
*/

.dashboard_grid {
  margin: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 360px);
  grid-template-rows: auto auto auto;
  column-gap: 10px;
  row-gap: 10px;
  width: 100%;
}

.dashboard_grid_item {
  border-radius: "15px";

  align-items: "center";
  width: "100%";
  overflow: "hidden";
  text-align: "-webkit-center";
  overflow-y: "auto";
  justify-self:stretch;
/*  
  border: 1px solid rgba(0, 0, 0, 0.8);
*/
  text-align: center;
  padding: 20px;
  border-radius: 6px;

}

.dashboard_grid_item_two_column {
  grid-column-end: span 2;
}

.dashboard_grid_item_two_row {
  grid-row-end: span 2;
}

.dashboard_title {  font-size: 1.5em;}

.arquella_scheme_primary {
  color:white;
  background-color:#4cc1bd;
}

.arquella_scheme_secondary {
  color:#4cc1bd;
  background-color:#dcf5f3;
}

.width-100{ width: 100%; }
.align-self-center { align-self: center}
.text-align-center { text-align: center}

.arquella_maintenance {
  background-color: lightgray;
  color: white;
}

.grid-form div{
  font-size: 1.2em;
  font-weight: bold;
}

.grid-form input{
  font-size: 1em;
  border-radius: 3px;
}

.grid-form {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 10px;
  row-gap: 10px;
  width: 100%;
}

dialog {
  color:#4cc1bd;
  background-color: #dcf5f3;
  border-radius: 4px;
  border: 2px #4cc1bd solid;
}
dialog::backdrop {
  background-color: white;
}

.styledButton {
  border-radius: 3px;
  background-color: #4cc1bd;
  color: white;
  padding: 2px;
  margin: 2px;
}
.styledButton:hover {
  background-color: #dcf5f3;
  color: #4cc1bd;
}

.width-half { width: 50%; }

.form_record_title {  

  width: 50px;
  font-weight: bold; 

}

.live_call_card {
  width: 100%;
  display: grid;
  grid-template-columns: 40px auto 70px;
  grid-template-rows: auto;
  column-gap: 10px;
  row-gap: 10px;
  margin: 3px;
}

.span-rows-two {
  grid-column-start: 2;
  grid-column-end: 4
}

.text-align-left    { text-align: -webkit-left; }
.text-align-right   { text-align: -webkit-right; }
.justify-self-right { justify-self: right; }

.live_call_card {}

/*
.live_call_card div {
  border: 1px solid rgba(0, 0, 0, 0.8); 
}
*/

.width-50 {
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.align-top {
  
}

.grey_frame{
  border: 2px grey solid;
  padding: 5px;
  margin: 5px;
  border-radius: 6px;
}

.width-full { width: 100%; }
.justify-content-between { justify-content : space-between; }

.HidePrint {  display: none !important;}

.nav-collapse {
  width: 1px !important;
  order: -1;
  flex: 0 1 auto;
  background: #dcf5f3;
  display: flex;
  flex-direction: column;
  color: #4cc1bd;
  min-height: 100vh;
  padding: 20px;
  margin:top;
}


.topmenubar{
  background-color: #4cc1bd;
 
}

.flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rounded-full {
  border-radius: 9999px;
}

.w-8 {
  width: 2rem;
}
.h-8 {
  height: 2rem;
}

.usermenu-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55/0.8); 
}

 
.padding-2 {
  padding: 5px;
}


.absolute {
  position: absolute;
}


.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(255,255,255,var(--bs-bg-opacity))!important;
  background-color: rgba(var(--bs-white-rgb),var(--bs-bg-opacity))!important;
}

.py-1 {
  padding-top: 0.25rem!important;
  padding-bottom: 0.25rem!important;
}
.mt-2 {
  margin-top: 0.5rem!important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}
.ring-opacity-5 {
  --tw-ring-opacity: 0.05;
}
.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0/var(--tw-ring-opacity));
}
.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow,0 0 transparent);
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0/0.1),0 4px 6px -4px rgb(0 0 0/0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: 0 0 transparent,0 0 transparent,var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
}
.rounded-md {
  border-radius: 0.375rem;
}
.origin-top-right {
  -webkit-transform-origin: top right;
  transform-origin: top right;
}
.w-48 {
  width: 12rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.z-20 {
  z-index: 20;
}
.right-0 {
  right: 0;
}
.absolute {
  position: absolute;
}
.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(255,255,255,var(--bs-bg-opacity))!important;
  background-color: rgba(var(--bs-white-rgb),var(--bs-bg-opacity))!important;
}
.py-1 {
  padding-top: 0.25rem!important;
  padding-bottom: 0.25rem!important;
}
.mt-2 {
  margin-top: 0.5rem!important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}


.bg-aqSecondary {
  --tw-bg-opacity: 1;
  background-color: rgb(220 245 243/var(--tw-bg-opacity));
}

.text-center {
  text-align: center!important;
}

.m-3 {
  margin: 1rem!important;
}

.text-aqPrimary {
  --tw-text-opacity: 1;
  color: rgb(76 193 189/var(--tw-text-opacity));
}

.w-full {
  width: 100%;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}

.bg-aqPrimary{
  background-color: #4cc1bd;
}

.hider {
  width: 100%;
  height: 60px;
  position: absolute;
  z-index: 20;
  top: 77px;
  background-color: white;
}


.panel_row{

}
.panel_row:hover{
  background-color: #4cc1bd;
  color: white;
  cursor: pointer;
}

.margin-left-5{ margin-left: 5px; }
.margin-5 {margin:5px}
.panel_sound_option_title{
  min-width: 150px;
}

.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.alert_monitoring {
  background-color: red;
  color: white;
}
.highlight_hover:hover{
  background-color: #76baff;
}

.aqBackgroundSolid {
  background-color: #4CC1BC;
}