
h1 {
    font-size: 3vh;
    color: var(--lighthighlightColour);
}

h2 {
    font-size: 2vh;
}

h3 {
    font-size: 1vh;
}

p {
    font-size: 2vh;
}

nav {
    background-color: var(--primaryColour);
}
