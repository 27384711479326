

.arquella-table-spacing {
    border-spacing: 1px;
}

.maxheight {
    min-height: 92vh;
}

.center-self-align {
    align-self: center
}
.text-align-center {
    text-align: -webkit-center
}

.content_spacing {
    margin-left: 10em;
    margin-right: 10em;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  
  .font-faded{
    color: lightgray;
  }


  
.nav-areaaq {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    height: 58px;
  }
  
  
  .menusaq {
    display: flex;
    list-style: none;
  }
  
  .menu-itemsaq {
    position: relative;
    font-size: 14px;
  }
  
  .menu-itemsaq a {
    display: block;
    font-size: inherit;
    color: #4cc1bd;
    text-decoration: none;
  }
  
  .menu-itemsaq button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
  
  .menu-itemsaq a,
  .menu-itemsaq button {
    text-align: left;
    padding: 0.7rem 1rem;
  }
  
  .menu-itemsaq a:hover,
  .menu-itemsaq button:hover {
    background-color: #dcf5f3;
    color: #4cc1bd;
  }
  
  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  
  .dropdownaq {
    position: absolute;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none;
    color: #4cc1bd;
  }
  
  .dropdownaq.show {
    display: block;
  }
  
  .dropdownaq .dropdown-submenuaq {
    position: absolute;
    left: 100%;
    top: -7px;
  }

  .aq-grid {
    display: grid;
    grid-template-columns: [start col-a] 50% [col-b] 50%  [stop end last]; 
  }
  
  .aq-grid div {
    padding: 30px;
  }