.resident_details_container {
  /*
  background-color: var(--primaryColour);
  */
  color: var(--detailsTextColour);
  
  padding: 10px;
 
  display: grid;
  grid-template-columns: 100px 200px;
  column-gap: 5px;
  row-gap: 5px;
  grid-template-rows: repeat(5px);
  overflow-x: hidden;
  margin-bottom: 50px;

}

.resident_header{
  display: flex;
  flex-direction: row;

}

.resident_avatar {
  width: 150px;
  overflow: hidden;
}

.resident_avatar img {
  width: 90%;
  vertical-align: middle;
  border-radius: 50%;
}

.resident_container {
  width: 1000px;
}

.resident_container hr {
  width: 100%;
}

.resident_content {

}

.resident_nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.resident_nav .options {
  background-color: var(--primaryColour);
  color: var(--lighthighlightColour);
  padding: 5px;
  border-radius: 6px;
  min-width: 70px;
  text-align: center;

}


.resident_call_screen {
  border: 1px solid lightgrey;
  border-radius: 6px;
  margin: 10px;
  padding: 10px; 
}