.cover {
    display: flex;
    align-items: center;
    height: 50vh;
    justify-content: center;
    background-color: pink;
}

.grid_dash {
    padding: 2%;
    width: 100%;
    height: 100%;
    background-color: var(--chartBGColour);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(200px, 1fr));   
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.grid_item {
    background-color: white;
    border-radius: 6px;
    padding: 5px;
}

.grid_col_span_2 {
    grid-column: auto / span 2;
}

/*
@media (max-width: 390px), screen and (orientation: portrait) {
*/

@media only screen and (orientation: portrait) {

    .grid_item {
/*        background-color: yellow;*/
        grid-column: auto / span 2;
    }
    .grid_dash {
        grid-template-rows: auto;   
    }

    .grid_dash {
        grid-template-columns: repeat(1, 1fr);
    }

}

@media only screen and (max-width:30em) {

    .grid_item {
        grid-column: auto / span 2;
/*        background-color: red;*/

    }

    .grid_dash {
        grid-template-rows: auto;   
    }
    
}

.carer_container_grid {
    display: grid;
    grid-template-columns: auto 2fr 1fr 1fr;
}

.carer_item {
    font-size: 2vh;
    background-color: white;
    color:gray;
}

header {
    
    display: flex;
    flex-direction: row;
}

body {
    margin: auto;
}

.wrapper {
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px; 
    border: 1px solid black;
}

.fade-in { animation: fadeIn 1s; }

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }