.ob_rec_list {
    display: flex;
    flex-direction: row;
}

.ob_rec_row {
    display: flex;
    flex-direction: column;
}


.panel_lists_container{
    display: flex;
    flex-direction: row;
}