.care_dashboard {

    margin: 50px;
    overflow-x: hidden;
    max-width: 1520px;
    display: flex;
    flex-direction: column;
    
}

.care_dashboardBlocks {

    margin: 50px;
    display: grid;
    grid-template-columns: repeat(3, 300px);
    column-gap: 50px;
    row-gap: 30px;
    grid-template-rows: repeat(20, 100px);
    overflow-x: hidden;
    max-width: 1520px;
    
}
