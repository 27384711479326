@media screen and (max-width: 500px) {
    .menuItem_container, .main_content_menu { 
        display: none; 
    }
    

    .LiveDashboard_Container {
       
        /*flex-direction: column;*/
    }
}