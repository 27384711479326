/*
header.ndash {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

header.ndash div.menuitems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
*/
.ndash_content{
    width: 100%;
}

main.ndash {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-right: 100px; 
}

div.ndash_side {
    width: 200px;
}

.promoBar {
    width:100%;
    background-color: #4cc1bd;
}

.ndash_layout{
    background-color: lightgray;
    width: 100%;
    height: 100%;
}